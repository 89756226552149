import {find, flatMap, forEach, map, set, size, uniq, values} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import RecommendationAnnotation from 'components/RecommendationAnnotation';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import FactSection from 'components/shared/fact_section';
import useAppContext from 'conf/AppContext';

import 'components/GenotypePicker/index.scss';

const propTypes = {
  annotationId: PropTypes.string.isRequired,
  note: PropTypes.element,
  source: PropTypes.string,
  alleles: PropTypes.object,
  chemicalId: PropTypes.string,
};
/**
 * Displays fields for the user to pick a specific genotype which is then used to display specific recommendations.
 *
 * @param {object} props - props container
 * @param {string} props.annotationId - accession ID of the "parent" annotation
 * @param {*} note - optional note to display below picker
 * @param {string} source - optional data source string for source-specific content
 * @param {Array<object>} alleles - a map of genes to alleles to use as options
 * @param {string} chemicalId - optional, the specific chemical to show the recommendation for
 */
export default function GenotypePicker({annotationId, note, source, alleles, chemicalId}) {
  const appContext = useAppContext();
  const [drugs, setDrugs] = useState(undefined);
  const [picks, setPicks] = useState({});
  const genes = Object.keys(alleles);

  useEffect(() => {
    const initPicks = {};
    forEach(
      Object.keys(alleles),
      (gene) => {
        if (!singlePloidy(gene)) {
          initPicks[gene] = [null, null];
        } else {
          initPicks[gene] = [null];
        }
      });
    setPicks(initPicks);
  }, [alleles]);

  const renderOption = (alleleName) => <option value={alleleName} key={alleleName}>{alleleName}</option>;

  const onChange = (idx, symbol) => async (e) => {
    const pickedAllele = e.target.value;
    const newPicks = set(picks || {}, `${symbol}.${idx}`, pickedAllele);
    setPicks(newPicks);

    try {
      const r = await appContext.api.post(
        `site/gsi/${annotationId}/${chemicalId}`,
        {json: newPicks, parseJson: true},
      );
      setDrugs(find(values(r?.data?.drugs), (d) => d.drug.id === chemicalId) || null);
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  const renderGenePicker = (symbol) => (
    <div className="genotype-specific-annotations-picker" key={symbol}>
      <div>Pick genotype for {symbol}</div>
      <select onChange={onChange(0, symbol)}>
        <option value="">--</option>
        {map(alleles[symbol], renderOption)}
      </select>
      {!singlePloidy(symbol) && (
        <select onChange={onChange(1, symbol)}>
          <option value="">--</option>
          {map(alleles[symbol], renderOption)}
        </select>
      )}
    </div>
  );

  let resultSection;
  if (drugs) {
    const resultGuideline = find(
      flatMap(values(drugs), (d) => d.annotations),
      (g) => g?.id === annotationId,
    );
    if (resultGuideline) {
      const {genotypes, hasNoRecommendation, phenotypes, recommendationAnnotations} = resultGuideline;
      if (size(recommendationAnnotations) > 0) {
        const multipopulation = size(uniq(map(recommendationAnnotations, (a) => a.population))) > 1;
        resultSection = (
          <div className="guidelinePickerResultSection">
            {map(recommendationAnnotations, (r) =>
              <RecommendationAnnotation key={r.id} phenotypes={phenotypes} genotypes={genotypes} multipopulation={multipopulation} {...r} />)}
          </div>
        );
      } else if (hasNoRecommendation) {
        resultSection = (
          <div className="guidelinePickerResultSection">
            <div className="recommendationAnnotation">This genotype has no recommendation</div>
          </div>
        );
      }
    }
  }

  return (
    <FactSection title="Specify a genotype for specific annotations" id="tour-annotation-picker">
      {map(genes, renderGenePicker)}
      <small>
        <p>Alleles not present in the above pull-down menus have no guideline recommendation.</p>
        {source === 'DPWG' && (
          <p>
            <InfoLink href="/page/dpwgMapping">Important information about alleles in DPWG guidelines.</InfoLink>
          </p>
        )}
      </small>
      {note}
      {resultSection}
    </FactSection>
  );
}
GenotypePicker.propTypes = propTypes;

function singlePloidy(gene) {
  return gene === 'MT-RNR1';
}
