import {forEach, map, size} from 'lodash';
import {useEffect} from 'react';

import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import EditButton from 'components/Button/Edit';
import GenotypePicker from 'components/GenotypePicker';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import Section from 'components/Section';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import DosingInfoTag from 'components/Tag/DosingInfo';
import TagHeader from 'components/Tag/Header';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import RetiredTag from 'components/Tag/Retired';
import TestingInfoTag from 'components/Tag/TestingInfo';
import EditControls from 'components/edit/EditControls';
import Fact from 'components/shared/fact';
import HtmlContainer, {addHtmlContainerWhenLoaded} from 'components/shared/html_container';
import TableStylizer from 'components/shared/tableStylizer';
import YoutubeEmbed from 'components/shared/youtube_embed';
import {useTour} from 'components/tour/hook';
import Types, {guidelineAnnotationProps} from 'conf/types';
import usePediatricContext from 'context/Pediatric';


/**
 * Guideline annotation detail display
 *
 * @param {object} props - props container
 * @param {string} props.id - guideline annotation id
 * @param {string} props.source - source of the guideline
 * @param {boolean} props.recommendation - false if there's no recommendation, true otherwise
 * @param {boolean} props.pediatric - true if this is pediatric
 * @param {string} props.userId - last curator to modify
 * @param {object} props.summaryMarkdown - summary markdown object
 * @param {object} props.textMarkdown - main body markdown object
 * @param {string} props.descriptiveVideoId - YouTube video ID
 * @param {boolean} props.nested - true if this detail will not appear on it's own page
 * @param {Array<object>} props.relatedChemicals - array of chemicals that appear in the same annotation
 * @param {boolean} props.hasGenotypes - true if this guideline has genotype-specific annotations
 * @param {boolean} props.hasTestingInfo - true if this guideline has testing info
 * @param {boolean} props.alternateDrugAvailable - true if this guideline has an alternate drug
 * @param {boolean} props.dosingInformation - true if this guideline has dosing information
 * @param {boolean} props.otherPrescribingGuidance - true if this guideline has other prescribing guidance
 * @param {boolean} props.approved - true if this guideline is approved
 * @param {boolean} props.published - true if this guideline exists on live
 * @param {object} props.sections - map of data annotation type to array of those data annotations
 * @param {string} props.retirementReason - string of text related to guideline retirement
 * @param {object} props.gsiAlleles - string of text related to guideline retirement
 * @param {Array<string>} props.relatedGenes - genes associated with this annotation
 */
export default function GuidelineAnnotationDetail({
  id,
  source,
  recommendation = true,
  pediatric = false,
  pediatricMarkdown,
  userId,
  summaryMarkdown,
  textMarkdown,
  descriptiveVideoId,
  nested = true,
  relatedChemicals,
  hasGenotypes = false,
  sections,
  hasTestingInfo = false,
  dosingInformation = false,
  alternateDrugAvailable = false,
  otherPrescribingGuidance = false,
  approved = true,
  published = true,
  retirementReason,
  gsiAlleles,
  relatedGenes,
}) {
  const pediatricContext = usePediatricContext();

  const readyForTour = useTour('guidelineAnnotation');
  useEffect(() => {
    readyForTour(['#tour-annotation-picker']);
  }, [readyForTour]);

  const hasRecommendations = size(gsiAlleles) > 0;
  const note = sections?.alleleNotes?.[0]?.markdown?.html ? <HtmlContainer html={sections?.alleleNotes[0]?.markdown.html} /> : null;
  const editButtons = [
    <EditButton href={`/edit/guidelineAnnotation/${id}`} key="edit" />,
    <EditButton href={`/edit/guidelineAnnotation/${id}/recommendations`} label="Recommendations" key="editRec" />,
  ];
  forEach(
    relatedGenes,
    (gene) => editButtons.push(<EditButton key={gene.id} href={`/edit/haplotypes/${gene.id}`} label={`Edit ${gene.symbol} Alleles`} />),
  );
  editButtons.push(<ApproveAndDiffButtons objCls="GuidelineAnnotation" objId={id} approved={approved} published={published} iconOnly={false} key={`approveDiff${id}`} />);
  editButtons.push(<PharmgkbTagButton objId={id} objCls="guidelineAnnotation" key={`tagBtn${id}`} />);
  editButtons.push(<span className="ml-2 mr-2 fixed-width-font" key={`userSpan${id}`}>owner: {userId || <em>unassigned</em>}</span>);

  return (
    <>
      <EditControls>
        {editButtons}
      </EditControls>

      <TagHeader belowEditControls={true}>
        {retirementReason && <RetiredTag />}
        {hasTestingInfo ? <TestingInfoTag /> : null}
        {dosingInformation ? <DosingInfoTag context="guidelineAnnotation" /> : null}
        {alternateDrugAvailable ? <AlternateDrugTag context="guidelineAnnotation" /> : null}
        {otherPrescribingGuidance ? <OtherGuidanceTag context="guidelineAnnotation" /> : null}
        {pediatric && <PediatricTag />}
      </TagHeader>

      <Section title="Summary" id="tour-guideline-summary">
        {addHtmlContainerWhenLoaded(summaryMarkdown?.html, {className: 'summary-text'})}
      </Section>

      {!recommendation && <div className="alert alert-danger mb-5">No recommendation</div>}

      {hasGenotypes && (
        <div>
          {hasRecommendations && size(relatedChemicals) > 1 && (
            <>
              <p className="font-italic">This guideline contains genotype/phenotype-specific recommendations for
                multiple drugs. Choose a drug below then enter a genotype to see the matching recommendation.
              </p>
              <ul>
                {map(relatedChemicals, (c) => (
                  <li key={c.id}><a href={`${Types.chemical.url(c.id)}/guidelineAnnotation/${id}`}>{c.name}</a></li>
                ))}
              </ul>
            </>
          )}
          {hasRecommendations && size(relatedChemicals) === 1 && (
            <GenotypePicker
              annotationId={id}
              alleleNote={note}
              source={source}
              chemicalId={relatedChemicals[0].id}
              alleles={gsiAlleles}
            />
          )}
        </div>
      )}

      {
        pediatricContext.isPediatricMode && pediatric && pediatricMarkdown && (
          <Section title="Pediatric Excerpts" className="alert alert-info">
            <HtmlContainer html={pediatricMarkdown.html} className="summary-text" />
          </Section>
        )
      }

      <Section title="Annotation" id="tour-guideline-annotation">
        {descriptiveVideoId && <YoutubeEmbed videoId={descriptiveVideoId} />}
        <TableStylizer isInSidebarLayout={nested} html={textMarkdown?.html} />
      </Section>


      <Fact label="PharmGKB ID" literal={id} inline={true} />
    </>
  );
}
GuidelineAnnotationDetail.propTypes = guidelineAnnotationProps;
